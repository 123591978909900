<template>
  <div class="col-sp-12 col-sd-4 row-sp-12">
    <div class="container" :class="{overdue: timer.seconds > overDueValue && order.status === 'open'}">
      <div class="box" :style="color" @click="expand = !expand">
        <div class="grid-12 orderUserPriceInformations">
          <div class="col-sp-12 col-sd-8 row-sp-12">
            <div class="orderNameId t-left">
              <p>{{ order.id }} - {{ order.firstname }} {{ order.lastname }} ({{ order.userId }})</p>
              <p>{{ order.status === 'open' ? timer.isoString : duration.string + ' (' + order.created.split(' ')[1] + ' - ' + order.delivered.split(' ')[1] + ')' }}</p>
            </div>
          </div>
          <div class="col-sp-12 col-sd-4 row-sp-12">
            <div class="subTotal">
              {{ order.subTotal.toFixed(2) }}€
            </div>
          </div>
        </div>

      </div>
      <div class="expand" :style="{'max-height': expand ? '500px' : '20px'}">
        <div>
          <div v-for="item in order.items.drinks" :key="item.id" class="drink">
            <div class="grid-12">
              <div class="col-sp-8 col-sd-8 row-sp-12">
                <div>{{ item.name }}</div>
              </div>
              <div class="col-sp-2 col-sd-2 row-sp-12">
                <div>{{ item.amount + item.unit }}</div>
              </div>
              <div class="col-sp-2 col-sd-2 row-sp-12">
                <div style="font-weight: bold; text-align: right;">{{ item.quantity }}</div>
              </div>
              <div class="col-sp-12 col-sd-12 row-sp-12">
                <div style="padding-top: 4px;">{{ item.price.toFixed(2) }}€</div>
              </div>
              <div class="col-sp-12 col-sd-12 row-sp-12">
                <hr class="separator" style="margin: 6px 0; opacity: 0.3;"/>
              </div>
            </div>
          </div>
          <div class="tip" v-if="order.items.tip">
            Trinkgeld: {{ order.items.tip[0].price.toFixed(2) }}€
          </div>
          <button class="button-confirm button confirm-button" v-if="order.status === 'open'" @click="fulfill">
            Bestätigen
          </button>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import {configStore} from "@/store/configStore";
import {aSyncData, pad, stringToDate} from "@/modules/main";

export default {
  name: "orderComp",
  props: {
    order: {
      type: Object,
      required: true
    }
  },
  emits: ['fulfill'],
  setup() {
    const config = configStore()

    return {config}
  },
  data(){
    return{
      expand: true,
      overDueValue: 450
    }
  },
  computed:{
    timer(){
      try {
        return this.config.calculateTimeString(stringToDate(this.order.created).getTime())
      }catch (e) {
        return {}
      }
    },
    color() {
      const halfOverDueValue = this.overDueValue / 2;
      const timer = this.order.status === 'open' ? this.timer.seconds : this.duration.seconds
      const red = timer >= halfOverDueValue ? 255 : Math.round(Math.min(255, Math.max(0, 255 - (halfOverDueValue- timer) / halfOverDueValue * 255)));
      const green = timer >= halfOverDueValue ? Math.round(Math.max(0, Math.min(255, 255 - ((timer - halfOverDueValue)) / halfOverDueValue * 255))) : 255;
      return {
        'background-color': `rgb(${red},${green},0)`
      };
    },
    duration() {
      let seconds = (stringToDate(this.order.delivered).getTime() - stringToDate(this.order.created).getTime())/1000
      let isoString
      if(seconds < 60*60){
        isoString = Math.floor(seconds/60) + ':' + pad(seconds % 60, 2)
      }else{
        isoString = Math.floor(seconds/3600) + ':' + pad(Math.floor(seconds % 3600 / 60), 2)  + ':' + pad(seconds % 60, 2)
      }
      return {string: isoString, seconds: seconds}
    }
  },
  methods: {
    fulfill(){
      aSyncData('orders/fulfill', {orderId: this.order.id})
          .then(r => {
            console.log(r)
            if(r.status === 250) this.$emit('fulfill', this.order.id)
          })
          .catch(e => {console.error(e)})
    }
  }
}
</script>

<style scoped>




.container{
  width: calc(100% - 8px);
  margin: 16px 0;
  border-radius: 20px;
  overflow: hidden;
}
.box{
  text-align: center;
  padding: 16px 10px;
  z-index: 2;
  position: relative;
}

.orderNameId{
  padding-left: 10px;
}

@media (max-width: 767px) {
  .orderNameId{
    text-align: center;
    padding: unset;
  }
}

.orderUserPriceInformations{
  align-items: center;
}

.expand{
  background-color: rgba(0,0,0,0.05);
  transition: all 0.5s ease;
  z-index: 1;
  overflow: hidden;
}
.expand > div{
  padding: 20px;
  position: relative;
}
.tip{

}
.subTotal{
  font-weight: bold;
  font-size: 1.2em;
}
.drink{

}
.drink > div{
  margin: 6px 0;
}
.overdue{
  /*box-shadow: 0 0 0 0 rgb(176, 7, 7);*/
  transform: scale(1);
  /*animation: pulse 2s infinite;*/
}
@keyframes pulse {
  0% {
    transform: scale(0.95);
    box-shadow: rgba(100, 22, 22, 0.7) 0 0 10px;
  }

  70% {
    transform: scale(1);
    box-shadow: rgba(100, 22, 22, 0.7) 0 0 10px;
  }

  100% {
    transform: scale(0.95);
    box-shadow: rgba(100, 22, 22, 0.7) 0 0 10px;
  }
}

.button-confirm{
  background-color: #2068ff;
  color: white;
  width: 100%;
  margin-top: 24px;
}

.overdue .confirm-button {
  display: inline-block; /* Der Button soll inline dargestellt werden */
  cursor: pointer; /* Mauszeiger wird zum Zeiger */
  animation-name: huer; /* Name der Animation */
  animation-duration: 0.6s; /* Dauer der Animation */
  animation-timing-function: ease-in-out; /* Art der Animation */
  animation-iteration-count: infinite; /* Anzahl der Wiederholungen */
}

@keyframes huer {
  0% { transform: translateY(0); } /* Startposition */
  50% { transform: translateY(-5px); } /* Zwischenposition */
  100% { transform: translateY(0); } /* Endposition */
}
</style>