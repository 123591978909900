
<template>
  <div class="bg-neutral-50 h-full">
    <div class="lg:pl-72">
      <main class="py-4">
        <div class="px-4">
          <div class="mb-2">
            <div>
              <nav class="hidden sm:flex" aria-label="Breadcrumb">
                <ol role="list" class="flex items-center space-x-4">
                  <li>
                    <div class="flex">
                      <a href="/albums" class="text-sm font-medium text-gray-500 hover:text-gray-700">Übersicht</a>
                    </div>
                  </li>
                </ol>
              </nav>
            </div>
            <div class="mt-2 md:flex md:items-center md:justify-between">
              <div class="min-w-0 flex-1">
                <h2 class="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">Mediengalerie</h2>
              </div>
              <div class="mt-4 flex flex-shrink-0 md:ml-4 md:mt-0">
                <button @click="watermarkModal = true" type="button" class="ml-3 inline-flex items-center rounded-md bg-white px-2 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                  Wasserzeichen bearbeiten
                </button>
                <button @click="createModal = true" type="button" class="ml-3 inline-flex items-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600">
                  Album erstellen
                </button>
              </div>
            </div>
          </div>
          <section>
            <div class="border-b border-gray-200 bg-white px-4 py-5 sm:px-6 mb-4">
              <h3 class="text-base font-semibold leading-6 text-gray-900 mb-4">Gemeldete Bilder</h3>
              <div v-if="reports.length === 0">
                <p class="text-center text-sm text-gray-500">Zurzeit sind keine Meldungen offen</p>
              </div>
              <ul role="list" class="grid grid-cols-1 gap-6 sm:grid-cols-3 lg:grid-cols-4">
                <li class="col-span-1 divide-y divide-gray-200 rounded-lg bg-white shadow relative" v-for="report in reports" :key="report.id" >
                  <div>
                    <img class="w-full rounded bg-slate-100" :src="config.projectURL + 'assets/images/media/' + report.file + '_thumbnail.jpg'">
                    <h3 class="px-6 mt-3 text-l font-bold">Begründung:</h3>
                    <p class="px-6 mt-1 text-sm text-gray-500" v-html="nl2br(report.reason)"></p>
                  </div>
                  <div class="mt-16">
                    <div class="-mt-px flex divide-x divide-gray-200">
                      <div class="-ml-px flex w-full flex-1 absolute bottom-0">
                        <button @click="editReport(report.imageId, 1 ,report.file, report.albumId)" type="button" class="relative inline-flex w-0 flex-1 items-center hover:bg-slate-100 cursor-pointer justify-center gap-x-3 rounded-br-lg border border-transparent py-4 text-sm font-semibold text-gray-900">
                          Ablehnen
                        </button>
                        <button @click="editReport(report.imageId, 2, report.file, report.albumId)" type="button" class="relative inline-flex w-0 flex-1 items-center hover:bg-slate-100 cursor-pointer justify-center gap-x-3 rounded-br-lg border border-transparent py-4 text-sm font-semibold text-gray-900">
                          Bild löschen
                        </button>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>

            </div>
          </section>
          <section>
            <div class="border-b border-gray-200 bg-white px-4 py-5 sm:px-6">
              <h3 class="text-base font-semibold leading-6 text-gray-900 mb-4">Alben Übersicht</h3>
              <ul role="list" class="grid grid-cols-1 gap-6 sm:grid-cols-3 lg:grid-cols-4">
                <li class="col-span-1 divide-y divide-gray-200 rounded-lg bg-white shadow relative" v-for="album in albums" :key="album.id" >
                  <div>
                    <img class="w-full rounded bg-slate-100" :src="config.projectURL + 'assets/images/media/' + album.cover + '_thumbnail.jpg'" v-if="album?.cover?.length">
                    <h3 class="px-6 mt-3 text-xl font-bold">{{album.title}}</h3>
                    <p class="px-6 mt-1 text-sm text-gray-500" v-html="nl2br(album.description)"></p>
                  </div>
                  <div class="flex w-full items-center justify-between space-x-6 p-6">
                    <div class="flex-1">
                      <div class="flex items-center space-x-3">
                        <h3 class="text-sm font-bold text-gray-900">Anzahl Bilder: <span class="font-medium text-gray-700">{{ album.counter }}</span></h3>
                      </div>
                      <div class="flex items-center space-x-3">
                        <h3 class="text-sm font-bold text-gray-900">Veröffentlicht: <span class="font-medium text-gray-700">{{album.online ? 'Ja' : 'Nein'}}</span></h3>
                      </div>
                    </div>
                  </div>
                  <div class="mt-10">
                    <div class="-mt-px flex divide-x divide-gray-200">
                      <div class="-ml-px flex w-full flex-1 absolute bottom-0">
                        <a @click="$router.push({name: 'SingleAlbum', params: {albumId: album.id}})" class="relative inline-flex w-0 flex-1 items-center hover:bg-slate-100 cursor-pointer justify-center gap-x-3 rounded-br-lg border border-transparent py-4 text-sm font-semibold text-gray-900">
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-5 w-5 text-gray-400">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
                          </svg>
                          Bearbeiten
                        </a>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </section>
        </div>
      </main>
    </div>
    <notification class="my-4" v-if="editMessage" type="success" title="Anfrage bearbeitet" message="Die Meldung wurde erfolgreich bearbeitet."/>
    <notification class="my-4" v-if="deleteMessage" type="success" title="Löschen erfolgreich" message="Das Wasserzeichen wurde erfolgreich gelöscht."/>
    <TransitionRoot as="template" :show="watermarkModal">
      <Dialog as="div" class="relative z-10" @close="watermarkModal = false">
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
          <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </TransitionChild>
        <div class="fixed inset-0 z-10 overflow-y-auto">
          <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                <DialogPanel class="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                  <div class="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                    <button type="button" class="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2" @click="watermarkModal = false">
                      <span class="sr-only">Close</span>
                      <XMarkIcon class="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                  <div class="sm:flex sm:items-start">
                    <div class="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-blue-100 sm:mx-0 sm:h-10 sm:w-10">
                      <PhotoIcon class="h-6 w-6 text-blue-600" aria-hidden="true" />
                    </div>
                    <div class="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                      <DialogTitle as="h3" class="text-base font-semibold leading-6 text-gray-900">Wasserzeichen bearbeiten</DialogTitle>
                      <div>
                        <img class="w-100 mt-4 bg-slate-100 p-4 br-10" :src="config.projectURL + '/assets/images/waterMark/' + config.getCurrentOrganizer?.waterMark + '.png'" v-if="config.getCurrentOrganizer?.waterMark?.length"/>
                        <div class="relative mt-4">
                          <label for="banner" class="absolute w-full -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900">Wasserzeichen auswählen (.png Datei)</label>
                          <input id="banner" @change="onFileChange" type="file" ref="fileInput" accept="image/png" class="w-full rounded bg-white px-2 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                        </div>
                        <div class="relative mt-4">
                          <button type="button" v-if="config.getCurrentOrganizer?.waterMark" @click="deleteModal = true" class="w-full rounded bg-white px-2 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">Wasserzeichen löschen</button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                    <button @click="setWaterMark()" v-if="file" class="inline-flex w-full justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 sm:ml-3 sm:w-auto">Speichern</button>
                    <button @click="watermarkModal = false;" type="button"  class="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto">Abbrechen</button>
                  </div>
                </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
    <TransitionRoot as="template" :show="createModal">
      <Dialog as="div" class="relative z-10" @close="createModal = false">
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
          <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </TransitionChild>
        <div class="fixed inset-0 z-10 overflow-y-auto">
          <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
              <form @submit.prevent="createAlbum()">
                <DialogPanel class="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                  <div class="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                    <button type="button" class="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2" @click="createModal = false">
                      <span class="sr-only">Close</span>
                      <XMarkIcon class="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                  <div class="sm:flex sm:items-start">
                    <div class="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-blue-100 sm:mx-0 sm:h-10 sm:w-10">
                      <PhotoIcon class="h-6 w-6 text-blue-600" aria-hidden="true" />
                    </div>
                    <div class="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                      <DialogTitle as="h3" class="text-base font-semibold leading-6 text-gray-900">Album erstellen</DialogTitle>
                      <div>
                        <div class="relative mt-4">
                          <label for="title" class="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900">Titel des Albums</label>
                          <input id="title" v-model="title" required type="text" placeholder="Titel des Albums" class="px-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                        </div>
                        <div class="relative mt-4">
                          <label for="description" class="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900">Beschreibung</label>
                          <textarea id="description" v-model="description" required placeholder="Beschreibung" rows="6" class="px-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"></textarea>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                    <button type="submit" class="inline-flex w-full justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 sm:ml-3 sm:w-auto">Erstellen</button>
                    <button @click="createModal = false;" type="button"  class="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto">Abbrechen</button>
                  </div>
                </DialogPanel>
              </form>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
    <confirmModal v-if="deleteModal" type="danger" title="Bist du dir Sicher?" message="Möchtest du das Wasserzeichen wirklick löschen?" cancelButton="Abbrechen" button="Löschen" @action="setWaterMark(true); deleteMessage = true;"/>
  </div>
</template>
<script>
/* eslint-disable */
import { ChevronLeftIcon, PhotoIcon, ChevronRightIcon, CurrencyEuroIcon, TicketIcon, QrCodeIcon, CheckCircleIcon, ClockIcon, ArrowDownIcon, ArrowUpIcon,   BriefcaseIcon, CalendarIcon, EnvelopeIcon, ChevronDownIcon, LinkIcon, MapPinIcon, PencilSquareIcon, BanknotesIcon, CursorArrowRaysIcon} from '@heroicons/vue/20/solid'
import { Switch, RadioGroup, RadioGroupDescription, RadioGroupLabel, RadioGroupOption, Menu, MenuButton, MenuItem, MenuItems, Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { ExclamationTriangleIcon, XMarkIcon } from '@heroicons/vue/24/outline'
import notification from "@/components/notifications/notification";
import alert from "@/components/alerts/alert";
import confirmModal from "@/components/modals/confirmModal";
import { useVuelidate } from '@vuelidate/core'
import {aSyncData, nl2br} from "@/modules/main";
import {configStore} from "@/store/configStore";
import {authStore} from "@/store/authStore";
import {userStore} from "@/store/userStore";
import Loader from "@/components/basic/loader.vue";
export default {
  name: "universalAlbumsView",
  components: {Loader, ChevronLeftIcon, ChevronRightIcon, TicketIcon, CurrencyEuroIcon, QrCodeIcon,
    notification,alert,RadioGroup, RadioGroupDescription, RadioGroupLabel, RadioGroupOption,
    CheckCircleIcon, ClockIcon, MapPinIcon, Switch, PencilSquareIcon, BanknotesIcon,
    ArrowDownIcon, ArrowUpIcon,   BriefcaseIcon, CalendarIcon, EnvelopeIcon, ChevronDownIcon, LinkIcon,
    Menu, MenuButton, MenuItem, MenuItems, Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot,
    ExclamationTriangleIcon, XMarkIcon, PhotoIcon, CursorArrowRaysIcon, confirmModal},
  setup () {
    const auth = authStore()
    const config = configStore()
    const user = userStore()
    return {
      auth, config, user,
      v$: useVuelidate()
    }
  },
  data () {
    return {
      title: '',
      description: '',
      albums: [],
      reports: [],
      file: null,
      loading: false,
      watermarkModal: false,
      createModal: false,
      deleteModal: false,
      deleteMessage: false,
      editMessage: false
    }
  },
  mounted() {
    this.loadAlbums()
    this.getReports()

  },
  methods: {
    editReport(id,status, file, albumId){
      this.editMessage = false
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      fD.append('organizerId', this.config.organizerId)
      fD.append('id', id)
      fD.append('status', status)
      fD.append('file', file)
      fD.append('albumId', albumId)
      this.axios.post('albums/editReport', fD)
          .then((r) => {
            console.log(r.status, r.data)
            if(r.status === 250){
              this.getReports()
              this.editMessage = true
            }
          })
    },
    async getReports () {
      aSyncData('albums/getReports', {
        organizerId: this.config.organizerId
      })
          .then((r) => {
            if(r.status !== 250) return
            this.reports = r.data
          })
          .catch(console.log)
          .finally(() => {this.loading=false})
    },
    nl2br(str){
      return nl2br(str)
    },
    onFileChange(e) {
      const files = e.target.files || e.dataTransfer.files;
      if (!files.length)
        return;
      this.file = files[0];
      console.log(this.file)
    },
    async createAlbum () {
      this.loading = true

      aSyncData('albums/create', {
        title: this.title,
        description: this.description,
        organizerId: this.config.organizerId
      })
          .then((r) => {
            if(r.status !== 250) return
            this.$router.push({name: 'SingleAlbum', params: {albumId: r.data.id}})
          })
          .catch(console.log)
          .finally(() => {this.loading=false})
    },
    loadAlbums () {
      aSyncData('albums/getAll',{
        organizerId: this.config.organizerId
      })
          .then(r => {
            console.log(r)
            this.albums = r.data.albums
          })
          .catch(console.log)
    },
    setWaterMark(deleteWaterMark = false){
      this.loading = true
      const postData = {}
      if(!deleteWaterMark) {
        postData.waterMark = this.file
      }
      aSyncData('albums/setWaterMark', postData,
          {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          },
      )
          .then(r => {
            console.log(r)
            this.config.organizer = r.data.organizer
          })
          .catch(console.log)
          .finally(() => {
            this.loading=false
            this.file = null
          })
    }
  }
}
</script>

<style scoped>

</style>