<template>
  <div class="bg-white p-4 rounded shadow mt-4">
    <div class="mt-2 md:flex md:items-center md:justify-between">
      <div class="min-w-0 flex-1">
        <div class="relative">
          <label for="name" class="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900">Kategoriename</label>
          <input @input="$emit('editCategory', category.id, name)" type="text" v-model="name" name="name" id="name" class="block w-full rounded-md border-0 text-l px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:leading-6" placeholder="Kategoriename" />
        </div>
      </div>
      <div class="mt-4 flex flex-shrink-0 md:ml-4 md:mt-0">
          <span class="isolate inline-flex rounded-md shadow-sm">
            <button @click="$emit('listOrder', index, true)" :disabled="!index" :class="{disabled: !index}" type="button" class="relative inline-flex items-center rounded-l-md bg-slate-50 px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10">
              <span class="sr-only">Nach Oben</span>
              <ArrowSmallUpIcon class="h-5 w-5" aria-hidden="true" />
            </button>
            <button @click="$emit('listOrder', index, false)"  :disabled="index + 1 >= amount" :class="{disabled: index + 1 >= amount}" type="button" class="relative -ml-px inline-flex items-center bg-slate-50 px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10">
              <span class="sr-only">Nach Unten</span>
              <ArrowSmallDownIcon class="h-5 w-5" aria-hidden="true" />
            </button>
            <button  @click="deleteModal = true" type="button" class="relative inline-flex items-center gap-x-1.5 rounded-r-md bg-slate-50 px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10">
              <TrashIcon class="-ml-0.5 h-5 w-5 text-gray-400" aria-hidden="true" />
              Löschen
            </button>
          </span>
      </div>
    </div>
    <div>
      <div class="productsInputfields" v-for="(item, index1) in items" :key="item.id">
        <div class="mt-2 md:flex md:items-center md:justify-between">
          <div class="min-w-0 flex-1">
            <div class="relative inline-flex">
              <label for="itemname" class="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900">Name</label>
              <input @change="editBarItem(item.id)" type="text" v-model="item.name" name="itemname" id="itemname" class="block w-full border-0 text-sm px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:leading-6" placeholder="Name" />
            </div>
            <div class="relative inline-flex">
              <label for="itemcaption" class="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900">Beschreibung</label>
              <input @change="editBarItem(item.id)" type="text" v-model="item.caption" name="itemcaption" id="itemcaption" class="block w-full border-0 text-sm px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:leading-6" placeholder="Beschreibung" />
            </div>
            <div class="relative inline-flex">
              <div class="mt-2 flex rounded-md shadow-sm">
                <div class="relative flex flex-grow items-stretch focus-within:z-10">
                  <label for="itemamount" class="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900">Maßangabe</label>
                  <input @change="editBarItem(item.id)" type="number" step="0.00" v-model="item.amount" name="itemamount" id="itemamount" class="block w-full  border-0 text-sm px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:leading-6" placeholder="Maßangabe" />
                </div>
                <select v-model="item.unit" @change="editBarItem(item.id)" class="relative inline-flex items-center px-3 py-2 text-sm sm:flex bg-slate-100 font-semibold text-gray-500 hover:bg-slate-200">
                  <option>ml</option>
                  <option>cl</option>
                  <option>l</option>
                  <option>g</option>
                </select>
              </div>
            </div>
            <div class="relative inline-flex">
              <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                <span class="text-gray-500 sm:text-sm">€</span>
              </div>
              <label for="price" class="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900">Preis (inkl. MwSt.)</label>
              <input v-model="item.price" type="number" step="0.01" name="price" id="price" class="block w-full border-0 py-1.5 pl-7 pr-12 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" placeholder="0.00" aria-describedby="price-currency" />
              <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                <span class="text-gray-500 sm:text-sm" id="price-currency">EUR</span>
              </div>
            </div>
          </div>
          <div class="mt-4 flex flex-shrink-0 md:ml-4 md:mt-0">
          <span class="isolate inline-flex rounded-md shadow-sm">
            <button @click="listOrder(index1, true)" :disabled="!index1" :class="{disabled: !index1}" type="button" class="relative inline-flex items-center rounded-l-md bg-slate-50 px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10">
              <span class="sr-only">Nach Oben</span>
              <ArrowSmallUpIcon class="h-5 w-5" aria-hidden="true" />
            </button>
            <button @click="listOrder(index1, false)" :disabled="items.length - 1 === index1" :class="{disabled: items.length - 1 === index1}" type="button" class="relative -ml-px inline-flex items-center bg-slate-50 px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10">
              <span class="sr-only">Nach Unten</span>
              <ArrowSmallDownIcon class="h-5 w-5" aria-hidden="true" />
            </button>
            <button @click="deleteBarItem(item.id)" type="button" class="relative inline-flex items-center gap-x-1.5 rounded-r-md bg-slate-50 px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10">
              <TrashIcon class="-ml-0.5 h-5 w-5 text-gray-400" aria-hidden="true" />
              Löschen
            </button>
          </span>
          </div>
        </div>
      </div>
      <h5 class="font-bold mt-2 text-sm"> Hinzufügen</h5>
      <div class="md:flex md:items-center md:justify-between">
        <div class="min-w-0 flex-1">
          <div class="relative inline-flex">
            <label for="itemname" class="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900">Name</label>
            <input type="text" v-model="itemName" name="itemname" id="itemname" class="block w-full border-0 text-sm px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:leading-6" placeholder="Name" />
          </div>
          <div class="relative inline-flex">
            <label for="itemcaption" class="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900">Beschreibung</label>
            <input type="text" v-model="itemCaption" name="itemcaption" id="itemcaption" class="block w-full border-0 text-sm px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:leading-6" placeholder="Beschreibung" />
          </div>
          <div class="relative inline-flex">
            <div class="mt-2 flex rounded-md shadow-sm">
              <div class="relative flex flex-grow items-stretch focus-within:z-10">
                <label for="itemamount" class="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900">Maßangabe</label>
                <input type="number" step="0.00" v-model="itemAmount" name="itemamount" id="itemamount" class="block w-full  border-0 text-sm px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:leading-6" placeholder="Maßangabe" />
              </div>
              <select v-model="itemUnit" class="relative inline-flex items-center px-3 py-2 text-sm sm:flex bg-slate-100 font-semibold text-gray-500 hover:bg-slate-200">
                <option>ml</option>
                <option>cl</option>
                <option>l</option>
                <option>g</option>
              </select>
            </div>
          </div>
          <div class="relative inline-flex">
            <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
              <span class="text-gray-500 sm:text-sm">€</span>
            </div>
            <label for="price" class="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900">Preis (inkl. MwSt.)</label>
            <input @keyup.enter="addBarItem()" v-model="itemPrice" type="number" step="0.01" name="price" id="price" class="block w-full border-0 py-1.5 pl-7 pr-12 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" placeholder="0.00" aria-describedby="price-currency" />
            <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
              <span class="text-gray-500 sm:text-sm" id="price-currency">EUR</span>
            </div>
          </div>
        </div>
        <div class="mt-4 flex flex-shrink-0 md:ml-4 md:mt-0">
          <span class="isolate inline-flex rounded-md shadow-sm">
             <button @click="addBarItem()" type="button" class="relative inline-flex items-center rounded-md px-3 py-2 text-sm sm:flex bg-blue-600 font-semibold text-white hover:bg-blue-700">
                Hinzufügen
            </button>
          </span>
        </div>
      </div>
    </div>
  </div>
  <TransitionRoot as="template" :show="deleteModal">
    <Dialog as="div" class="relative z-10" @close="deleteModal = false">
      <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
        <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
      </TransitionChild>
      <div class="fixed inset-0 z-10 overflow-y-auto">
        <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
            <DialogPanel class="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
              <form @submit.prevent="$emit('deleteCategory', category.id)">
                <div class="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                  <button type="button" class="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2" @click="deleteModal = false">
                    <span class="sr-only">Close</span>
                    <XMarkIcon class="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div class="sm:flex sm:items-start">
                  <div class="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                    <ExclamationTriangleIcon class="h-6 w-6 text-red-600" aria-hidden="true" />
                  </div>
                  <div class="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                    <DialogTitle as="h3" class="text-base font-semibold leading-6 text-gray-900">Kategorie löschen?</DialogTitle>
                    <div class="mt-2">
                      <p class="text-sm text-gray-500">
                        Bist du sicher, dass du die Kategorie inkl. aller Getränke löschen möchtest?
                      </p>
                    </div>
                  </div>
                </div>
                <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                  <button type="submit" class="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto">Kategorie löschen</button>
                  <button type="button" class="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto" @click="deleteModal = false">Abbrechen</button>
                </div>
              </form>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>

</template>

<script>
import {authStore} from "@/store/authStore";
import {configStore} from "@/store/configStore";
import {userStore} from "@/store/userStore";
import {aSyncData} from "@/modules/main";
import { ArrowSmallUpIcon, ArrowSmallDownIcon, TrashIcon, ExclamationTriangleIcon, XMarkIcon } from '@heroicons/vue/24/outline'
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'

export default {
  name: "barCategory",
  components: {
    ArrowSmallUpIcon, ArrowSmallDownIcon,TrashIcon, ExclamationTriangleIcon, XMarkIcon,
    Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot
  },
  props: {
    category: Object,
    index: Number,
    amount: Number
  },
  emits: ['deleteCategory', 'editCategory', 'listOrder'],
  setup() {
    const auth = authStore()
    const config = configStore()
    const user = userStore()

    return {auth, config, user}
  },
  data(){
    return{
      items: [],
      name: this.category.name,
      deleteModal: false,
      itemName: '',
      itemCaption: '',
      itemUnit: 'ml',
      itemAmount: null,
      itemPrice: 0.00
    }
  },
  mounted() {
    this.getBarItems()
  },
  methods: {
    getBarItems(){
      aSyncData('barMenu/getBaritemsByBarCategory', {barCategoryId: this.category.id})
          .then(r => {
            console.log(r)
            if (r.status === 250) {
              r.data.forEach(i => {
                i.price = i.price.toFixed(2)
                this.items.push(i)
              })
              this.items = r.data.sort((a, b) => {return a.listOrder - b.listOrder})
            }
          })
    },
    addBarItem() {
      const _this = this
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      fD.append('barCategoryId', this.category.id)
      fD.append('name', this.itemName)
      fD.append('caption', this.itemCaption)
      fD.append('unit', this.itemUnit)
      fD.append('amount', this.itemAmount)
      fD.append('price', this.itemPrice)
      this.axios.post('barMenu/addBarItem', fD)
          .then(r => {
            if(r.status === 250){
              r.data.price = r.data.price.toFixed(2)
              _this.items.push(r.data)
              _this.itemName = ''
              _this.itemCaption = ''
              _this.itemUnit = 'ml'
              _this.itemAmount = null
              _this.itemPrice = null
            }
          })
    },
    deleteBarItem(barItemId){
      const _this = this
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      fD.append('barItemId', barItemId)
      this.axios.post('barMenu/deleteBarItem', fD)
          .then(r => {
            console.log(r)
            if(r.status === 250){
              _this.items =_this.items.filter(c => {
                return c.id !== barItemId
              })
            }
          })
    },
    editBarItem(barItemId){
      const item = this.items.find(i => i.id === barItemId)
      console.log(item)
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      fD.append('barItemId', barItemId)
      fD.append('name', item.name)
      fD.append('caption', item.caption)
      fD.append('unit', item.unit)
      fD.append('amount', item.amount)
      fD.append('price', item.price.replace(',', '.'))
      this.axios.post('barMenu/editBarItem', fD)
          .then(r => {
            console.log(r)
          })
    },
    listOrder(index, moveUp){
      for(let i = 0; i < this.items.length; i++ ){
        if(!moveUp && i === index){
          this.items[i].listOrder = i + 1;
        }else if(!moveUp && (i - 1) === index){
          this.items[i].listOrder = i - 1;
        }else if(moveUp && (i + 1) === index){
          this.items[i].listOrder = i + 1;
        }else if(moveUp && i === index){
          this.items[i].listOrder = i - 1;
        }else{
          this.items[i].listOrder = i;
        }
      }
      this.items = this.items.sort((a, b) => {return a.listOrder - b.listOrder})

      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      fD.append('barItems', JSON.stringify(this.items))
      this.axios.post('barMenu/changeOrderOfBarItems', fD)
    }
  }
}
</script>

<style scoped>

</style>