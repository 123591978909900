<template>
  <div>
    <div class="bg-neutral-50 h-full">
    <div class="lg:pl-72">
      <main class="py-4">
        <div class="px-4">
          <div class="mb-2">
            <div>
              <nav class="sm:flex" aria-label="Breadcrumb">
                <ol role="list" class="flex items-center space-x-4">
                  <li>
                    <div class="flex">
                      <a href="/muttizettel" class="text-sm font-medium text-gray-500 hover:text-gray-700">Übersicht</a>
                    </div>
                  </li>
                </ol>
              </nav>
            </div>
            <div class="mt-2 md:flex md:items-center md:justify-between">
              <div class="min-w-0 flex-1">
                <h2 class="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">Muttizettel</h2>
              </div>
              <div class="mt-4 flex flex-shrink-0 md:ml-4 md:mt-0">
              </div>
            </div>
          </div>
        </div>
        <div>
          <div class="grid-5 px-2 mb-4">
            <div class="col-sp-5 col-tb-2 col-sd-1 row-sp-5 p-2">
              <dl class="divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow md:grid-cols-3 md:divide-x md:divide-y-0">
                <div class="px-4 py-5 sm:p-6">
                  <dt class="text-base font-normal text-gray-900">Clubzettel Gesamt</dt>
                  <dd class="mt-1 flex items-baseline justify-between md:block lg:flex">
                    <div class="flex items-baseline text-2xl font-semibold text-indigo-600">
                      {{ stats.gesamtsumme }}
                      <span class="ml-2 text-sm font-medium text-gray-500">Clubzettel ausgefüllt</span>
                    </div>
                  </dd>
                </div>
              </dl>
            </div>
            <div class="col-sp-5 col-tb-2 col-sd-1 row-sp-5 p-2">
              <dl class="divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow md:grid-cols-3 md:divide-x md:divide-y-0">
                <div class="px-4 py-5 sm:p-6">
                  <dt class="text-base font-normal text-gray-900">Clubzettel diesen Monat</dt>
                  <dd class="mt-1 flex items-baseline justify-between md:block lg:flex">
                    <div class="flex items-baseline text-2xl font-semibold text-indigo-600">
                      {{ stats.anzahl_letzter_monat }}
                      <span class="ml-2 text-sm font-medium text-gray-500">Clubzettel ausgefüllt</span>
                    </div>
                  </dd>
                </div>
              </dl>
            </div>
            <div class="col-sp-5 col-tb-2 col-sd-1 row-sp-5 p-2">
              <dl class="divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow md:grid-cols-3 md:divide-x md:divide-y-0">
                <div class="px-4 py-5 sm:p-6">
                  <dt class="text-base font-normal text-gray-900">Nächstes Event (02.10)</dt>
                  <dd class="mt-1 flex items-baseline justify-between md:block lg:flex">
                    <div class="flex items-baseline text-2xl font-semibold text-indigo-600">
                      {{ stats.anzahl_next_event }}
                      <span class="ml-2 text-sm font-medium text-gray-500">Clubzettel ausgefüllt</span>
                    </div>
                  </dd>
                </div>
              </dl>
            </div>
            <div class="col-sp-5 col-tb-2 col-sd-1 row-sp-5 p-2">
              <dl class="divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow md:grid-cols-3 md:divide-x md:divide-y-0">
                <div class="px-4 py-5 sm:p-6">
                  <dt class="text-base font-normal text-gray-900">Clubzettel heute</dt>
                  <dd class="mt-1 flex items-baseline justify-between md:block lg:flex">
                    <div class="flex items-baseline text-2xl font-semibold text-indigo-600">
                      {{ stats.anzahl_heute }}
                      <span class="ml-2 text-sm font-medium text-gray-500">Clubzettel ausgefüllt</span>
                    </div>
                  </dd>
                </div>
              </dl>
            </div>
            <div class="col-sp-5 col-tb-2 col-sd-1 row-sp-5 p-2">
              <dl class="divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow md:grid-cols-3 md:divide-x md:divide-y-0">
                <div class="px-4 py-5 sm:p-6">
                  <dt class="text-base font-normal text-gray-900">Alter Aufsichtspersonen</dt>
                  <dd class="mt-1 flex items-baseline justify-between md:block lg:flex">
                    <div class="flex items-baseline text-2xl font-semibold text-indigo-600">
                      Ø {{ parseInt(stats.durchschnittsalter).toFixed(0) }}
                      <span class="ml-2 text-sm font-medium text-gray-500">Jahre</span>
                    </div>
                  </dd>
                </div>
              </dl>
            </div>
          </div>
        </div>
        <div class="grid-12">
          <section class="col-sp-12 row-sp-12 mb-4">
            <div class="px-4">
              <div class="border-b border-gray-200 bg-white px-4 pt-5 sm:px-6">
                <div class="-ml-4 -mt-2 flex flex-wrap items-center justify-between sm:flex-nowrap">
                  <div class="ml-4 mt-2">
                    <h3 class="text-xl font-semibold leading-6 text-gray-900">Ausgefüllte Muttizettel</h3>
                  </div>
                </div>
              </div>
              <div class="bg-white p-4 rounded shadow">
                <div class="relative mb-4 rounded-md shadow-sm">
                  <input @keydown="eventId = -1" v-model="searchQuery" type="search" class="px-4 block w-full rounded-md border-0 py-1.5 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" placeholder="Suche nach Muttizettel..." />
                  <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                    <MagnifyingGlassIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                  </div>
                </div>
                <div class="relative mt-4 mb-4">
                  <label for="eventId" class="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900">Nach Event filtern</label>
                  <select @change="searchQuery = eventId" v-model="eventId" id="eventId" class="text-l px-2 block w-full rounded-md border-0 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:leading-6">
                    <option value="-1" disabled>
                      Event...
                    </option>
                    <option v-for="event in events" :key="event.id" :value="renderDateForJS(event.startTime)+ ' - ' +event.title">
                      {{ renderDateForJS(event.startTime)+ ' - ' +event.title }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="bg-white p-4">
                <table class="min-w-full divide-y divide-gray-300">
                  <thead>
                  <tr>
                    <th scope="col" class="hidden py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0 sm:table-cell">Event</th>
                    <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Gast</th>
                    <th scope="col" class="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell">Aufsichtsperson</th>
                    <th scope="col" class="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell">Elternteil</th>
                    <th scope="col" class="relative py-3.5 pl-3 pr-4 sm:pr-0">
                      <span class="sr-only">Edit</span>
                    </th>
                  </tr>
                  </thead>
                  <tbody class="divide-y divide-gray-200 bg-white">
                  <tr v-for="mz in muttizettel" :key="mz.id" :value="mz.id" :class="[mz.title.toLowerCase().includes(searchQuery.toLowerCase())?'':mz.nameUser.toLowerCase().includes(searchQuery.toLowerCase())?'':mz.nameSupervisor.toLowerCase().includes(searchQuery.toLowerCase())?'':mz.nameParent.toLowerCase().includes(searchQuery.toLowerCase())?'':renderDateForJS(mz.startTime).includes(searchQuery.toLowerCase())?'':(renderDateForJS(mz.startTime)+' - '+mz.title.toLowerCase()).includes(searchQuery.toLowerCase())?'':'hidden']">
                    <td class="hidden whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0 sm:table-cell">
                      <div class="font-medium text-gray-900">{{ renderDateForJS(mz.startTime) }} {{ mz.title }}</div>
                      <div class="mt-1 text-gray-500">{{ mz.subTitle }}</div>
                    </td>
                    <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      <div class="text-gray-900">{{ mz.nameUser }}</div>
                      <div class="mt-1 text-gray-500">{{ mz.phoneUser }}</div>
                    </td>
                    <td class="hidden whitespace-nowrap px-3 py-4 text-sm text-gray-500 lg:table-cell">
                      <div class="text-gray-900">{{ mz.nameSupervisor }}</div>
                      <div class="mt-1 text-gray-500">{{ mz.phoneSupervisor }}</div>
                    </td>
                    <td class="hidden whitespace-nowrap px-3 py-4 text-sm text-gray-500 sm:table-cell">
                      <div class="text-gray-900">{{ mz.nameParent }}</div>
                      <div class="mt-1 text-gray-500">{{ mz.phoneParent }}</div>
                    </td>
                    <td class="whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                      <a @click="getById(mz.id)" class="text-indigo-600 hover:text-indigo-900 cursor-pointer">
                        Details<span class="sr-only">, {{ mz.nameUser }}</span>
                      </a>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </section>
        </div>
      </main>
    </div>
  </div>
    <TransitionRoot as="template" :show="detailsModal">
      <Dialog as="div" class="relative z-10" @close="detailsModal = false;">
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
          <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </TransitionChild>
        <div class="fixed inset-0 z-10 overflow-y-auto">
          <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
              <DialogPanel class="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                  <div class="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                    <button type="button" class="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2" @click="detailsModal = false;">
                      <span class="sr-only">Close</span>
                      <XMarkIcon class="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                  <div class="sm:flex sm:items-start">
                    <div class="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-blue-100 sm:mx-0 sm:h-10 sm:w-10">
                      <DocumentCheckIcon class="h-6 w-6 text-blue-600" aria-hidden="true" />
                    </div>
                    <div class="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                      <DialogTitle as="h3" class="text-base font-semibold leading-6 text-gray-900">
                        Muttizettel Details<br>
                        <span class="text-gray-400 text-sm font-normal">
                          Erstellt am: {{renderTimestampForJS(muttizettelDetails.timestamp)}} Uhr
                        </span>
                      </DialogTitle>
                    </div>
                  </div>
                  <div class="mt-4">
                  <ul role="list">
                    <li class="col-span-1 flex rounded-md shadow-sm mt-3">
                      <div class="bg-blue-700 flex w-16 flex-shrink-0 items-center justify-center rounded-l-md text-sm font-medium text-white">
                        <CalendarDaysIcon class="h-5 w-5 text-white" aria-hidden="true" />
                      </div>
                      <div class="flex items-center justify-between truncate w-full rounded-r-md border-b border-r border-t border-gray-200 bg-slate-50">
                        <div class="px-4 py-2 text-sm">
                          <a class="font-bold text-gray-900 hover:text-gray-600">
                            {{renderTimestampForJS(muttizettelDetails.startTime)}} Uhr
                          </a>
                          <p class="text-gray-500">
                            {{muttizettelDetails.title}}
                          </p>
                          <p class="text-gray-400 text-xs">
                            {{muttizettelDetails.subTitle}}
                          </p>
                        </div>
                      </div>
                    </li>
                    <li class="col-span-1 flex rounded-md shadow-sm mt-3">
                      <div class="bg-blue-700 flex w-16 flex-shrink-0 items-center justify-center rounded-l-md text-sm font-medium text-white">
                        <UserIcon class="h-5 w-5 text-white" aria-hidden="true" />
                      </div>
                      <div class="flex items-center justify-between truncate w-full rounded-r-md border-b border-r border-t border-gray-200 bg-slate-50">
                        <div class="px-4 py-2 text-sm">
                          <a class="font-bold text-gray-900 hover:text-gray-600">
                            Gast: {{muttizettelDetails.nameUser}}
                          </a>
                          <p class="text-gray-400 text-xs">
                            Telefon: {{muttizettelDetails.phoneUser}}
                          </p>
                          <p class="text-gray-400 text-xs">
                            E-Mail: {{muttizettelDetails.emailUser}}
                          </p>
                          <p class="text-gray-400 text-xs">
                            Adresse: {{muttizettelDetails.streetUser}}, {{muttizettelDetails.plzUser}} {{muttizettelDetails.cityUser}}
                          </p>
                          <p class="text-gray-400 text-xs">
                            Geburtsdatum: {{renderDobForJS(muttizettelDetails.dobUser)}}
                          </p>
                        </div>
                      </div>
                    </li>
                    <li class="col-span-1 flex rounded-md shadow-sm mt-3">
                      <div class="bg-blue-700 flex w-16 flex-shrink-0 items-center justify-center rounded-l-md text-sm font-medium text-white">
                        <UserIcon class="h-5 w-5 text-white" aria-hidden="true" />
                      </div>
                      <div class="flex items-center justify-between truncate w-full rounded-r-md border-b border-r border-t border-gray-200 bg-slate-50">
                        <div class="px-4 py-2 text-sm">
                          <a class="font-bold text-gray-900 hover:text-gray-600">
                            Aufsichtsperson: {{muttizettelDetails.nameSupervisor}}
                          </a>
                          <p class="text-gray-400 text-xs">
                            Telefon: {{muttizettelDetails.phoneSupervisor}}
                          </p>
                          <p class="text-gray-400 text-xs">
                            E-Mail: {{muttizettelDetails.emailSupervisor}}
                          </p>
                          <p class="text-gray-400 text-xs">
                            Adresse: {{muttizettelDetails.streetSupervisor}}, {{muttizettelDetails.plzSupervisor}} {{muttizettelDetails.citySupervisor}}
                          </p>
                          <p class="text-gray-400 text-xs">
                            Geburtsdatum: {{renderDobForJS(muttizettelDetails.dobSupervisor)}}
                          </p>
                          <img :src="muttizettelDetails.signature2" class="w-40" style="background-color: #ffffff33;"/>
                        </div>
                      </div>
                    </li>
                    <li class="col-span-1 flex rounded-md shadow-sm mt-3">
                      <div class="bg-blue-700 flex w-16 flex-shrink-0 items-center justify-center rounded-l-md text-sm font-medium text-white">
                        <UserIcon class="h-5 w-5 text-white" aria-hidden="true" />
                      </div>
                      <div class="flex items-center justify-between truncate w-full rounded-r-md border-b border-r border-t border-gray-200 bg-slate-50">
                        <div class="px-4 py-2 text-sm">
                          <a class="font-bold text-gray-900 hover:text-gray-600">
                            Elternteil: {{muttizettelDetails.nameParent}}
                          </a>
                          <p class="text-gray-400 text-xs">
                            Telefon: {{muttizettelDetails.phoneParent}}
                          </p>
                          <p class="text-gray-400 text-xs">
                            E-Mail: {{muttizettelDetails.emailParent}}
                          </p>
                          <p class="text-gray-400 text-xs">
                            Adresse: {{muttizettelDetails.streetSupervisor}}, {{muttizettelDetails.plzParent}} {{muttizettelDetails.cityParent}}
                          </p>
                          <p class="text-gray-400 text-xs">
                            Geburtsdatum: {{renderDobForJS(muttizettelDetails.dobParent)}}
                          </p>
                          <img :src="muttizettelDetails.signature" class="w-40" style="background-color: #ffffff33;"/>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
                  <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                    <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                      <button type="button" @click="detailsModal = false" class="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 sm:ml-3 sm:w-auto">
                        Okay
                      </button>
                      <button type="button" @click="download(muttizettelDetails.id)" class="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto" >
                        PDF herunterladen
                      </button>
                    </div>
                  </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
  </div>
</template>

<script>
/* eslint-disable */
import { authStore } from '@/store/authStore'
import { configStore } from '@/store/configStore'
import { userStore } from '@/store/userStore'
import {aSyncData} from "@/modules/main";
import {notificationStore} from "@/store/notificationStore";
import {Vue3Lottie} from "vue3-lottie";
import { UserGroupIcon, UserIcon, DocumentCheckIcon, TagIcon, CurrencyEuroIcon, TicketIcon, ClockIcon, CalendarDaysIcon, MagnifyingGlassIcon } from '@heroicons/vue/20/solid'
import { RadioGroup, RadioGroupDescription, RadioGroupLabel, RadioGroupOption, Menu, MenuButton, MenuItem, MenuItems, Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { ExclamationTriangleIcon, XMarkIcon } from '@heroicons/vue/24/outline'

export default {
  name: 'Muttizettel',
  title: 'Muttizettel',
  components: {
    Vue3Lottie,
    UserGroupIcon, UserIcon, DocumentCheckIcon,TagIcon, CurrencyEuroIcon, TicketIcon, ClockIcon, CalendarDaysIcon, MagnifyingGlassIcon, ExclamationTriangleIcon, XMarkIcon,
    RadioGroup, RadioGroupDescription, RadioGroupLabel, RadioGroupOption, Menu, MenuButton, MenuItem, MenuItems, Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot
  },
  setup () {
    const auth = authStore()
    const config = configStore()
    const user = userStore()
    const notification = notificationStore()

    return { auth, config, user, notification }
  },
  data () {
    return {
      muttizettel: [],
      events: [],
      stats: [],
      muttizettelDetails: [],
      eventId: '-1',
      detailsModal: false,
      searchQuery: '',
    }
  },
  mounted () {
    this.config.loading = false
    this.getData()
    this.getMuttizettel()
  },

  methods: {
    getData(){
      aSyncData('muttizettel/getData', {
        organizerId: this.config.organizerId,
      })
          .then(r => {
            if(r.status === 250){
              this.events = r.data.events
              this.stats = r.data.stats
            }
          })
          .catch(e => console.error(e))
    },
    getMuttizettel(){
      aSyncData('muttizettel/getMuttizettel', {
        organizerId: this.config.organizerId,
      })
          .then(r => {
            if(r.status === 250){
              this.muttizettel = r.data
            }
          })
          .catch(e => console.error(e))
    },
    getById(id){
      console.log('Test')
      aSyncData('muttizettel/getById', {
        id: id,
      })
          .then(r => {
            console.log(r)
            if(r.status === 250){
              this.muttizettelDetails = r.data
              this.detailsModal = true
            }
          })
          .catch(e => console.error(e))
    },
    renderTimestampForJS(date){
      const jsTimestamp = date.split(' ')
      const jsDate = jsTimestamp[0].split('-')
      const jsTime = jsTimestamp[1].split(':')
      return jsDate[2] + '.' + jsDate[1] + '.' + jsDate[0] + ' ' + jsTime[0] + ':' + jsTime[1]
    },
    renderDateForJS(date){
      const jsTimestamp = date.split(' ')
      const jsDate = jsTimestamp[0].split('-')
      return jsDate[2] + '.' + jsDate[1] + '.' + jsDate[0]
    },
    renderDobForJS(date){
      if (date){
        const jsDate = date.split('-')
        return jsDate[2] + '.' + jsDate[1] + '.' + jsDate[0]
      }
    },
    download(id){
      this.loading = true
      aSyncData('muttizettel/download', {id: id}, {
        responseType: 'blob',
      })
          .then(r => {
            console.log(r)
            if(r.status === 250){
              const link = document.createElement('a')
              link.href = URL.createObjectURL(r.data)
              link.setAttribute('download', id + '-muttizettel.pdf')
              document.body.appendChild(link)
              link.click()
              document.body.removeChild(link)
              URL.revokeObjectURL(link.href)
            }
          })
          .catch(e => {})
          .finally(() => {this.loading = false})
    }
  }
}
</script>

<style>

</style>
